import {Component,Input} from '@angular/core'

@Component({
    selector: 'client-list-items',
    templateUrl: './client-list-items.component.html'
})

export class ClientListItemsComponent {
    @Input() eve:any

}



  
  