import { Component, Input, SimpleChanges, OnDestroy, ViewChild } from "@angular/core";
import { FormControl } from '../../../../node_modules/@angular/forms';
import { FileService } from '../../shared/file.service';
import { share, takeUntil } from '../../../../node_modules/rxjs/operators';
import { MatRadioChange, MatDrawer } from '../../../../node_modules/@angular/material';
import { RecordService } from '../../services/record/record.service';
import { Subject } from '../../../../node_modules/rxjs';
import { CommunicationService } from '../../services/communication/communication.service';

@Component({
    selector: 'product-tab',
    templateUrl: './product-tab.component.html',
    styles:[`
        /*.custom-base-panel{
            max-width:0%;
            -webkit-box-flex:0 0 50%;
            flex:0 0 50%;
            transition: max-width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1), opacity 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 0.3s;
            opacity:0
        }

        .custom-col-6-width {
            max-width:50%!important;
            opacity:1;
        }*/

        .main-box{
            height:67vh;
        }
        .icon-custom-class {width: 30px!important;height:30px!important;font-size:25px!important; text-shadow: 1px 1px 1px black;}
        .icon-container{text-align:center}
    `]
})

export class ProductTabComponent implements OnDestroy{
    @Input() clientList:any
    @ViewChild("productDrawer",{static:false}) productDrawer:MatDrawer
    @Input() type:number
    private ngUnsubscribe = new Subject()
    showRightPanel:boolean = false
    disableClick:boolean = false
    surnameFilter:any = ''
    surnameFilterClass:boolean = false
    surnameInputFilter:string
    peselFilter:any = ''
    peselFilterClass:boolean = false
    peselInputFilter:string
    bankFilter:any = ''
    bankFilterClass:boolean = false
    bankInputFilter:string = ''
    productFilter:any = ''
    productFilterClass:boolean = false
    productInputFilter:string =''
    teamFilter:any = ''
    teamFilterClass:boolean = false
    teamInputFilter:string =''
    creditApplicationFilter:any = ''
    creditApplicationFilterClass:boolean = false
    creditApplicationInputFilter:string
    nipFilter:any = ''
    nipFilterClass:boolean = false
    nipInputFilter:string
    ownerFilter:any = ''
    ownerFilterClass:boolean = false
    ownerInputFilter:string
    prevFilter:any = ''
    prevFilterClass:boolean = false
    prevInputFilter:string
    mobileFilter:any = ''
    mobileFilterClass:boolean = false
    mobileInputFilter:string
    resultFilter:any = ''
    resultFilterClass:boolean = false
    statusInputFilter:string = ''
    clientTypeFilter:any = ''
    clientTypeFilterClass:boolean = false
    clientTypeInputFilter:string = ''
    pcl:any
    showFilterHeder:boolean = false
    showCleanFiltersBtn:boolean = false
    productFilterArr:any
    bankFilterArr:any
    statusFilterArr:any
    teamFilterArr:any
    clientm:any
    resultsBoxes:any
    recordId:number
    OriginCallbackId:number
    hideClientData:boolean = false
    applicationNumberHeader:number
    showTabFilters:boolean = false


    constructor(
        private file:FileService,
        private record:RecordService,
        private communicationService:CommunicationService
    ){}

    ngOnInit(){
        this.communicationService.changeEmittedPanel$.subscribe(data=>{
            data ? this.productDrawer.close() && this.refreshCurrentTab():null
        })
    }

    applyProductsFilter($event,filter){
        const filterValue = typeof $event === "string" ? $event :(event.target as HTMLInputElement).value;
        this.pcl ? null:this.pcl=this.clientList

        switch(filter){
            case "surname":{
                this.surnameFilter = filterValue
                this.surnameFilter !== ''? this.surnameFilterClass=true:this.surnameFilterClass=false;
                (this.surnameFilter === '' && this.surnameInputFilter !== '')?this.surnameInputFilter = '':null
                break;
            }
            case "pesel":{
                this.peselFilter = filterValue
                this.peselFilter !== ''? this.peselFilterClass=true:this.peselFilterClass=false;
                (this.peselFilter === '' && this.peselInputFilter !== '')?this.peselInputFilter = '':null
                break;
            }
            case "nip":{
                this.nipFilter = filterValue
                this.nipFilter !== ''? this.nipFilterClass=true:this.nipFilterClass=false;
                (this.nipFilter === '' && this.nipInputFilter !== '')?this.nipInputFilter = '':null
                break;
            }
            case "clientType":{
                this.clientTypeFilter = filterValue
                this.clientTypeFilter !== ''? this.clientTypeFilterClass=true:this.clientTypeFilterClass=false;
                (this.clientTypeFilter === '' && this.clientTypeInputFilter !== '')?this.clientTypeInputFilter = '':null
                break;
            }
            case "product":{
                this.productFilter = filterValue
                this.productFilter !== ''? this.productFilterClass=true:this.productFilterClass=false;
                (this.productFilter === '' && this.productInputFilter !== '')?this.productInputFilter = '':null
                break;
            }
            case "team":{
                this.teamFilter = filterValue
                this.teamFilter !== ''? this.teamFilterClass=true:this.teamFilterClass=false;
                (this.teamFilter === '' && this.teamInputFilter !== '')?this.teamInputFilter = '':null
                break;
            }
            case "owner":{
                this.ownerFilter = filterValue
                this.ownerFilter !== ''? this.ownerFilterClass=true:this.ownerFilterClass=false;
                (this.ownerFilter === '' && this.ownerInputFilter !== '')?this.ownerInputFilter = '':null
                break;
            }
            case "prev":{
                this.prevFilter = filterValue
                this.prevFilter !== ''? this.prevFilterClass=true:this.prevFilterClass=false;
                (this.prevFilter === '' && this.prevInputFilter !== '')?this.prevInputFilter = '':null
                break;
            }
            case "mobile":{
                this.mobileFilter = filterValue
                this.mobileFilter !== ''? this.mobileFilterClass=true:this.mobileFilterClass=false;
                (this.mobileFilter === '' && this.mobileInputFilter !== '')?this.mobileInputFilter = '':null
                break;
            }
            case "bank":{
                this.bankFilter = filterValue
                this.bankFilter !== ''? this.bankFilterClass=true:this.bankFilterClass=false;
                (this.bankFilter === '' && this.bankInputFilter !== '')?this.bankInputFilter = '':null
                break;
            }
            case "status":{
                this.resultFilter = filterValue
                this.resultFilter !== ''? this.resultFilterClass=true:this.resultFilterClass=false;
                (this.resultFilter === '' && this.statusInputFilter !== '')?this.statusInputFilter = '':null
                break;
            }
            case "creditApplication":{
                this.creditApplicationFilter = filterValue
                this.creditApplicationFilter !== ''? this.creditApplicationFilterClass=true:this.creditApplicationFilterClass=false;
                (this.creditApplicationFilter === '' && this.creditApplicationInputFilter !== '')?this.creditApplicationInputFilter = '':null
                break;
            }
        }

        this.showCleanFiltersBtn = (
            this.surnameFilter !== '' ||
            this.peselFilter !== '' ||
            this.nipFilter !== '' ||
            this.clientTypeFilter !== '' ||
            this.productFilter !== '' ||
            this.bankFilter !== '' ||
            this.resultFilter !== '' ||
            this.creditApplicationFilter !== '' ||
            this.teamFilter !== '' ||
            this.ownerFilter !== '' ||
            this.prevFilter !== '' ||
            this.mobileFilter !== ''
        )

        this.clientList = this.pcl.filter(el=>
            el.bank.toLowerCase().includes(this.bankFilter.toLowerCase()) && 
            el.productName.toLowerCase().includes(this.productFilter.toLowerCase()) &&
            el.clientLastName.toLowerCase().includes(this.surnameFilter.toLowerCase()) &&
            el.creditApplicationNr.toLowerCase().includes(this.creditApplicationFilter.toLowerCase()) &&
            el.pesel.toLowerCase().includes(this.peselFilter.toLowerCase()) &&
            el.nip.toLowerCase().includes(this.nipFilter.toLowerCase()) &&
            el.clientType.toLowerCase().includes(this.clientTypeFilter.toLowerCase()) &&
            el.productName.toLowerCase().includes(this.productFilter.toLowerCase()) &&
            el.userName.toLowerCase().includes(this.ownerFilter.toLowerCase()) &&
            el.userTeamName.toLowerCase().includes(this.teamFilter.toLowerCase()) &&
            el.result.toLowerCase().includes(this.resultFilter.toLowerCase()) &&
            el.creditAnalystName.toLowerCase().includes(this.prevFilter.toLowerCase()) &&
            el.mobileAdviserName.toLowerCase().includes(this.mobileFilter.toLowerCase())
        )
        this.clientList.length === 0 ? this.showFilterHeder=true:null       
    }

    returnClassProp(value){
        value < 1? false:true
    }

    clearSingleFilter(eve,val){
        this.applyProductsFilter(eve,val)
    }

    clearFilters(){
        this.clientList = this.pcl
        this.surnameFilterClass = false
        this.peselFilterClass = false
        this.bankFilterClass = false
        this.productFilterClass = false
        this.creditApplicationFilterClass = false
        this.nipFilterClass = false
        this.resultFilterClass = false
        this.clientTypeFilterClass = false
        this.showCleanFiltersBtn = false
        this.teamFilterClass = false
        this.surnameInputFilter = ''
        this.peselInputFilter = ''
        this.bankInputFilter = ''
        this.productInputFilter = ''
        this.creditApplicationInputFilter = ''
        this.nipInputFilter = ''
        this.statusInputFilter = ''
        this.clientTypeInputFilter = ''
        this.teamInputFilter = ''
        this.ownerInputFilter = ''
        this.prevInputFilter = ''
        this.mobileInputFilter = ''
    }

    ngOnChanges(changes:SimpleChanges){
        if(changes.clientList.currentValue !== null){
            this.showTabFilters = true
            let temp_productFilterArr = new Set()
            let temp_bankFilterArr = new Set()
            let temp_statusFilterArr = new Set()
            let temp_teamFilterArr = new Set()
            for(var i of changes.clientList.currentValue){
                temp_productFilterArr.add(i.productName)
                temp_bankFilterArr.add(i.bank)
                temp_statusFilterArr.add(i.result)
                temp_teamFilterArr.add(i.userTeamName)
            }
            this.productFilterArr = Array.from(temp_productFilterArr)
            this.bankFilterArr = Array.from(temp_bankFilterArr)
            this.statusFilterArr = Array.from(temp_statusFilterArr)
            this.teamFilterArr = Array.from(temp_teamFilterArr)
        }
    }

    getProductDetails(recordId,creditApplicationID,creditApplicationNr){
        this.record.getRecordById(recordId,creditApplicationID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.productDrawer.open() 
            this.clientm = <any> resp.body
            this.clientm["creditApplicationId"] = creditApplicationID
            this.applicationNumberHeader = creditApplicationNr
            this.resultsBoxes = JSON.parse(this.clientm.contacResultJsonSring) === null ? []:JSON.parse(this.clientm.contacResultJsonSring).childItems
            this.recordId = this.clientm.recordId
            this.OriginCallbackId = this.clientm.callbackId 
            this.hideClientData = true
             
        })
    }

    refreshCurrentTab(){
        this.communicationService.emitChange()
        this.record.getProductsList(this.type)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.clientList = <any> resp.body
            if(this.clientList.length > 0){
                this.showTabFilters = true
            }
            this.communicationService.emitChange()
        })
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onOpenedChange(event){
        if(event === false){
            this.clientm = undefined
        }
    }

    



    // clientTypeOptionChange($event){
    //     this.applyProductsFilter($event,'clientType')
    // }

}