import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()

export class AllowedIPService{
    baseUrl: string = environment.backend.baseURL

    constructor(private http:HttpClient){}

    addWhiteIp(form){
        return this.http.post(`${this.baseUrl}` + '/Access/addWhiteIp', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data
                })
            );
    }

    getWhiteIpList(){
        return this.http.get(`${this.baseUrl}` + '/Access/getWhiteIpList',{observe:'response',withCredentials:true})
    }

    deleteWhiteIp(ipId){
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(ipId),
        };
        return this.http.delete(`${this.baseUrl}` + '/Access/deleteWhiteIp?ipID=' + ipId,options)
            .pipe(
                map(data => {
                    return data;
                })
            );
    }
}