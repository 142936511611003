import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './user/login.component';
import { HomePageComponent } from './homepage/home-page.component';
import { ClientContactDetailsComponent } from './clientcontactdetails/client-contact-details.component';
import { AddNewClientComponent } from './addnewclient/add-new-client.component';
import { AddNewEmployeeComponent } from './controlpanel/employeeaddnew/add-new-employee.component';
// import { EmployeesListComponent } from './employee/employees-list.component';
import { EmployeeEditComponent } from './employeeedit/employee-edit.component';
import { ControlPanelComponent } from './controlpanel/control-panel.component';
import { EmployeeListComponent } from './controlpanel/employeelist/employee-list.component';
import { UploadBaseComponent } from './controlpanel/uploadbase/upload-base.component';
import { BaseHistoryComponent } from './controlpanel/basehistory/base-history.component';
import { AddClientComponent } from './controlpanel/addclient/add-client.component';
import { ClientListComponent } from './controlpanel/clientslist/clients-list.component';
import { DictionariesComponent } from './controlpanel/dictionaries/dictionaries.component';
import { PermissionsListComponent } from './controlpanel/persmissionslist/permissions-list.component';
import { PasswordManagerComponent } from './passwordmanager/password-manager.component';
import { Error404Component } from './errors/404.component';
import { NewsPanelComponent } from './controlpanel/newspanel/news-panel-admin.component';
import { AuthService } from './services/authservice/auth.service';
import { LeaveRequestComponent } from './leaverequest/leave-request.component';
import { LeaveRequestsComponent } from './controlpanel/leaverequests/leave-requests.component';
import { InvoiceListComponent } from './controlpanel/invoicelist/invoice-list.component';
import { EmployeeSplitComponent } from './controlpanel/employeesplit/employee-split.component';
import { TalkTimeComponent } from './controlpanel/talktime/talk-time.component';
import { SalaryFormComponent } from './controlpanel/salaryform/salary-form.component';
import { AllowedIpComponent } from './controlpanel/allowedIp/allowed-ip.component';
import { AddNewEmployeeNewComponent } from './controlpanel/employeeaddnew/add-new-emplyee-new.component';
import { ManageBaseComponent } from './controlpanel/managebase/manage-base.component';
import { NewDocTreeComponent } from './controlpanel/editdoctree/new-doc-tree.component';


const routes: Routes = [
  {path:'',redirectTo:'/login',pathMatch:'full'},
  {path:'login',component:LoginComponent,data:{animation:'AnimIn'}},
  {path:'home',component:HomePageComponent,data:{animation:'AnimOut'}},
  {path:'record',component:ClientContactDetailsComponent,canActivate:[AuthService]},
  {path:'search',component:ClientContactDetailsComponent},
  {path:'addclient',component:AddClientComponent,canActivate:[AuthService]},
  {path:'leaverequest',component:LeaveRequestComponent,},
  {path:'error',component:Error404Component},
  
  {path:'employee-edit',component:EmployeeEditComponent,canActivate:[AuthService]},
  // {path:'employees-list',component:EmployeesListComponent},
  {path:'controlpanel',component:ControlPanelComponent,canActivate:[AuthService],children:[
    // { path: '', redirectTo: 'emplist', pathMatch: 'full' },
    {path:'',component:EmployeeListComponent},
    { path: 'emplist', component: EmployeeListComponent },
    {path:'addemployee',component:AddNewEmployeeNewComponent},
    {path:'uploadbase',component:UploadBaseComponent},
    {path:'managebase',component:ManageBaseComponent},
    {path:'basehistory',component:BaseHistoryComponent},
    {path:'addclient',component:AddClientComponent},
    {path:'clientslist',component:ClientListComponent},
    {path:'dictionaries',component:DictionariesComponent},
    {path:'permissionslist',component:PermissionsListComponent},
    {path:'editdoctree',component:NewDocTreeComponent},
    {path:'newspanel',component:NewsPanelComponent},
    {path:'leaverequests',component:LeaveRequestsComponent},
    {path:'invoicelist',component:InvoiceListComponent},
    {path:'employeesplit',component:EmployeeSplitComponent},
    {path:'talktime',component:TalkTimeComponent},
    {path:'salaryform',component:SalaryFormComponent},
    {path:'allowedip',component:AllowedIpComponent},
  ]},
  {path:'passwordmanager',component:PasswordManagerComponent},

  {path: '**', redirectTo: '/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
