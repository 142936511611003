import { Component, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from '../../../node_modules/@angular/forms';
import { MatDatepickerInputEvent, MatSnackBar } from '../../../node_modules/@angular/material';
import { formatDate, KeyValue } from '../../../node_modules/@angular/common';
import { Router } from '@angular/router';
import { HolidayService } from '../services/holiday/holiday.service';
import { catchError, takeUntil } from '../../../node_modules/rxjs/operators';
import { empty, Subject } from '../../../node_modules/rxjs';
import { SharedServicesService } from '../services/sharedservices/sharedservices.service';


@Component({
    selector:'leave-request',
    templateUrl:'./leave-request.component.html'
})

export class LeaveRequestComponent implements OnDestroy{
    leaveRequestForm = new FormGroup({
        leaveType: new FormControl('',Validators.required),
        startDate: new FormControl('',Validators.required),
        endDate: new FormControl('',Validators.required),
        notes: new FormControl('',Validators.required),
        employee: new FormControl('',Validators.required)
    })
    dateStart = new Date()
    dateEnd = new Date()
    dateStartMax
    dateEndMax
    n:number =0
    requestsList=[]
    showEmployee:boolean = true
    teamsDDL:any = []
    userDDL:any = []
    uw:any
    uz:any
    l4:any
    op:any
    ok:any
    in:any
    showLeaveStats:boolean = false
    requestsType:any =[]
    fixedDate:any
    private ngUnsubscribe = new Subject()
    canSubmit:any

    @ViewChild('leaveReqForm',{static:false}) leaveReqForm

    constructor(
        private router:Router,
        private snackBar:MatSnackBar,
        private holiday:HolidayService,
        private sharedServices: SharedServicesService
    ){}

    ngOnInit(){
        this.fixedDate = new Date().getFullYear()
        this.sharedServices.getLeaveRequestTypes()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.requestsType = <any>resp.body
        }) 

        if(this.router.url === '/leaverequest'){
            this.showLeaveStats = true
            this.showEmployee = false
            this.leaveRequestForm.controls.employee.setValidators(null)
            this.leaveRequestForm.controls.employee.setValue(null)
            
            this.getUserLeaveRequests()

            this.getUserUsedHolidays(0,1)
            this.getUserUsedHolidays(0,2)
            this.getUserUsedHolidays(0,3)
            this.getUserUsedHolidays(0,4)
            this.getUserUsedHolidays(0,5)
            this.getUserUsedHolidays(0,6) 
        } else {
            this.showLeaveStats = false
            this.showEmployee = true
            this.sharedServices.getDDLTeams()
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(resp=>{
                    this.teamsDDL = <any>resp.body
            })  
        } 
    }

    getUserLeaveRequests(){
        this.holiday.getUserLeaveRequests()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.requestsList = <any>resp.body
            this.requestsList.sort(function(a,b){
                let ac = a.leaveRequestId
                let bc = b.leaveRequestId
                return bc - ac
            })
        })
    }

    valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return a.value.localeCompare(b.value);
    }

    getUserDDL(event){
        this.showLeaveStats = false
        this.sharedServices.getDDLTeamUsers(event.value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.userDDL = <any>resp.body
        })      
    }

    getUserLeavesData(event){
        this.getUserUsedHolidays(event.value,1)
        this.getUserUsedHolidays(event.value,2)
        this.getUserUsedHolidays(event.value,3)
        this.getUserUsedHolidays(event.value,4)
        this.getUserUsedHolidays(event.value,5)
        this.getUserUsedHolidays(event.value,6)
        this.showLeaveStats = true
    }

    getUserUsedHolidays(userId,val){
        let user = userId
        let year = new Date().getFullYear()
        let type = val
        let status = 3

        this.holiday.getUserUsedHolidays(user,year,type,status)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            switch (type){
                case 1:{
                    this.uw = <any>resp.body
                    break;
                }
                case 2:{
                    this.uz = <any>resp.body
                    break;
                }
                case 3:{
                    this.l4 = <any>resp.body
                    break;
                }
                case 4:{
                    this.op = <any>resp.body
                    break;
                }
                case 5:{
                    this.ok = <any>resp.body
                    break;
                }
                case 6:{
                    this.in = <any>resp.body
                    break;
                }
            }
        })
    }

    leaveRequest(){
        let form = {
            "UserId":this.leaveRequestForm.value.employee === null ? 0:this.leaveRequestForm.value.employee,
            "DateFrom": formatDate(this.leaveRequestForm.value.startDate,"yyyy-MM-dd","en_PL"),
            "DateTo": formatDate(this.leaveRequestForm.value.endDate,"yyyy-MM-dd","en_PL"),
            "LeaveTypeId": this.leaveRequestForm.value.leaveType,
            "Comment": this.leaveRequestForm.value.notes,
        }
        this.holiday.addLeaveRequest(form)
        .pipe(
            catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 1000,
                    panelClass: "bg-danger"
                });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                let respObj = JSON.parse(resp.body)
                let message = respObj.message
                let respId = respObj.itemId
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });
                this.getUserLeaveRequests()
                this.leaveReqForm.resetForm()
        });
    }

    calculateDiff(dateFrom,dateTo) {
        var date1:any = new Date(dateFrom);
        var date2:any = new Date(dateTo);
        var diffDays:any = Math.floor((date2 - date1) / (1000 * 60 * 60 * 24)) + 1
        return diffDays;
    }

    validateMinMaxDate(event){
        switch(event.targetElement.id){
            case "dateStart": {
                this.dateEnd = event.value
                break;
            }
            case "dateEnd": {
                this.dateStartMax = event.value               
                break;
            }
        }
    }

    removeReq(id){
        this.holiday.deleteLeaveRequest(id)
        .pipe(
           catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                  });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
            let mess = <any> resp
            if(mess.isSuccess){
                this.requestsList.splice(this.requestsList.findIndex(itm=>itm.leaveRequestId === id),1)
            }
            this.snackBar.open(mess.message, "X", {
                duration: 10000,
                panelClass: mess.isSuccess? "bg-success" : "bg-danger"
              });
        })   
        //this.requestsList = this.requestsList.filter(itm=>itm.id !== id)
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}