import {Component, ViewEncapsulation, Input, SimpleChange, SimpleChanges, OnDestroy} from '@angular/core'
import { RecordService } from '../../services/record/record.service';
import { Router } from '@angular/router';
import { Subject } from '../../../../node_modules/rxjs';
import {takeUntil} from 'rxjs/operators'
import { ngIfAnimation, ngIfAnimation3 } from '../../animations/animations';

@Component({
    selector: 'callback-panel',
    templateUrl: './callback-panel.component.html',
    styles:[`
        .mat-expansion-panel-header {background-color:#3a3f44!important}
        .mat-expansion-panel-body {background-color:#3a3f44!important}
        .mat-expansion-panel-header-description{
            justify-content: space-between;
            align-items: center;            
        }
        .important {color: red;}
        .warn {color: orange;}
        
    `],
    encapsulation:ViewEncapsulation.None,
})

export class CallbackPanelComponent implements OnDestroy {
    
    @Input() callbackList:any
    @Input() groupedObj:any
    prevRecord:any = null
    recordHistory:any
    showCallbackLoader:boolean = true
    callbacks:any
    private ngUnsubscribe = new Subject()

    constructor(
        private record:RecordService,
        private router:Router
    ){}


    ngAfterViewInit(){
        
    }

    getTimerColor(time){
        let date1 = new Date(time).getTime()
        let date2 = new Date(Date.now()).getTime()
        let result = date1 - date2
        let diff = (result/1000)/60

        if(diff < 0){
            return "#ee5f5b"
        } else if(diff < 31){
            return "#f89406"
        } else {
            return "#62c462"
        }
    }

    openRecord(record,creditApplicationID){
        this.record.getRecordById(record,creditApplicationID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            if(resp){
                let body = <any> resp.body
                body["creditApplicationId"] = creditApplicationID
                sessionStorage.setItem("manual",body.recordId)
                this.router.navigateByUrl('/record',{state:{body}})
            }
        })
    }

    checkTooltipDesc(time){
        let date1 = new Date(time).getTime()
        let date2 = new Date(Date.now()).getTime()
        let result = date1 - date2
        let diff = (result/1000)/60

        if(diff < 0){
            return "Przeterminowany"
        } else if(diff < 31){
            return "Nadchodzący"
        } else {
            return "Oczekujący"
        }
    }

    getContactHistory(recordId){
        let flag = this.prevRecord === recordId ? false : true
        if(flag){
            this.prevRecord = recordId
            this.record.getRecordHistory(this.prevRecord)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(resp => {
                this.recordHistory = <any> resp.body
                //contactDateTime
                this.recordHistory.sort(function(a,b){
                    let ac = new Date(a.contactDateTime).getTime()
                    let bc = new Date(b.contactDateTime).getTime()
                    return bc - ac
                })
            })
        }
    }

    ngOnChanges(changes:SimpleChanges){
        this.showCallbackLoader = false
        this.callbacks = this.callbackList
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    onChange(eve){
        if(parseInt(eve.value) !== 0){
            this.callbacks = this.callbackList.filter(el => el.callbackStageID === parseInt(eve.value))
        } else {
            this.callbacks = this.callbackList
        }


    }
}