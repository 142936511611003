import { Component, ViewEncapsulation, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NewsPanelService } from '../../services/newspanel/newspanel.service';
import { tap, catchError, map,takeUntil } from 'rxjs/operators';
import {empty, Subject} from 'rxjs';
import { CommunicationService } from '../../services/communication/communication.service';
import { MatSnackBar } from '@angular/material';
import { formatDate, KeyValue } from '@angular/common';
import { TokenService } from '../../services/tokenservice/tokenservice.service';
import { DecodeTokenService } from '../../services/authservice/decodetoken.service';
import { SharedServicesService } from '../../services/sharedservices/sharedservices.service';

export interface tokenDetails {
    unique_name: string;
    BoxAccess: string;
    UserId: string;
    nbf: number;
    exp: number;
    iat:number;
  }

@Component({
    selector:'news-panel-admin',
    templateUrl:'./news-panel-admin.component.html',
    styles:[`
    .passmanager {float:right; color:#E05C65;padding-left:10px;font-size:0.8rem;}
    /*.custom-news-float{z-index: 10; position: absolute; right: 0; top: 0}*/
    .mat-tab-label-content {color:white}
    /* .mat-tab-body-content {height:300px} */
    .mat-ink-bar {background-color:white!important}
    .mat-tab-header-pagination-chevron {border-color:white}
    .mat-tab-header-pagination-disabled {border-color:red}
    .mat-tab-body-content {overflow:hidden!important}
    .mat-tab-label{min-width:80px!important}
    .mat-tab-label-active {
        background-color:#253340!important;
    }
    `],
    encapsulation:ViewEncapsulation.None
})

export class NewsPanelComponent implements OnDestroy{
    userToken
    tokenDetails
    publishMessage:boolean = true
    minDate: Date;
    teamDDLArr:any

    fixedDate:Date = new Date()
    //dd:Date = new Date()
    newsForm = new FormGroup({
        title: new FormControl('',Validators.required),
        newsBody: new FormControl('',Validators.required),
        importantFlag:new FormControl(),
        dpns: new FormControl({value:this.fixedDate,disabled:true}),
        teamDDL: new FormControl('',Validators.required)
    })
    private ngUnsubscribe = new Subject()


    constructor(
        private ns:NewsPanelService,
        private communicationService:CommunicationService,
        private snackBar:MatSnackBar,
        private tokenService:TokenService,
        private decodeToken:DecodeTokenService,
        private sharedServices: SharedServicesService
    ){
        this.fixedDate.setDate(this.fixedDate.getDate() + 7)
        this.minDate = new Date()
    }

    ngOnInit(){
        this.sharedServices.getAllDDL()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            let arr = <any> resp.body
            this.teamDDLArr = arr.teamsName            
        })
    }

    valueOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return a.value.localeCompare(b.value);
    }

    onSubmit() {

        let title = this.newsForm.controls.title.value
        let message = this.newsForm.controls.newsBody.value 
        let flag = this.newsForm.controls.importantFlag.value === null ? false : this.newsForm.controls.importantFlag.value
        let date = formatDate(this.newsForm.controls.dpns.value,"yyy-MM-dd","en_PL") + " 23:59:59"
        let teams = this.newsForm.controls.teamDDL.value

        this.communicationService.emitChange()
        this.ns.postNews(title,message,flag,date,teams)
            .pipe(
               catchError((err: any) => {
                    this.communicationService.emitChange();
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                this.communicationService.emitChange();
                  let respObj = JSON.parse(<any> resp.body)
                  let message = respObj.message
                  let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                  if(respObj.isSuccess){
                    this.newsForm.controls.title.setValue("")
                    this.newsForm.controls.title.markAsUntouched()
                    this.newsForm.controls.newsBody.setValue("")
                    this.newsForm.controls.newsBody.markAsUntouched()
                    this.newsForm.controls.teamDDL.setValue("")
                    this.newsForm.controls.teamDDL.markAsUntouched()
                    this.newsForm.controls.dpns.setValue(this.fixedDate)
                    this.newsForm.controls.importantFlag.setValue(false)
                  }
                  this.snackBar.open(message, "X", {
                      duration: 3000,
                      panelClass: messageClass
                  });
                
            }); 
    }

    changeHeader(flag){
        flag.index === 0 ? this.publishMessage = true:this.publishMessage = false
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    
}