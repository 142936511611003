import {Component, ViewEncapsulation,Inject, OnDestroy, ElementRef, ViewChild} from '@angular/core'
// import { ISession, EventService } from '../events';
import {trigger,state,style,animate,transition, keyframes} from '@angular/animations'
import { Router, Event, NavigationEnd } from '@angular/router';
import { logoAnimation, arrowButton, arrowicon, ngIfAnimation } from '../animations/animations';
import { AuthService } from '../services/authservice/auth.service';
import { RestApiService } from '../shared/rest-api.service';
import {empty, Subject, fromEvent} from 'rxjs';

import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { TokenService } from '../services/tokenservice/tokenservice.service';
import { NewsPanelService } from '../services/newspanel/newspanel.service';
import { filter,takeUntil,debounceTime, distinctUntilChanged,tap, catchError, map } from 'rxjs/operators';
import {MAT_BOTTOM_SHEET_DATA, MatMenu, MatMenuTrigger, MatMenuContent, MatMenuItem, MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material'
import { DecodeTokenService } from '../services/authservice/decodetoken.service';
import { MatSnackBar } from '@angular/material';
import { CommunicationService } from '../services/communication/communication.service';
import { RecordService } from '../services/record/record.service';
import { SalaryService } from '../services/salary/salary.service';
import { DialogData } from '../controlpanel/employeelist/employee-list.component';
import { FormControl, Validators, FormGroup } from '../../../node_modules/@angular/forms';
import { AccountService } from '../services/account/account.service';



export interface tokenDetails {
    unique_name: string;
    BoxAccess: string;
    UserId: string;
    nbf: number;
    exp: number;
    iat:number;
  }

@Component({
    selector: 'nav-bar',
    templateUrl:'./nav-bar.component.html',
    styles:[`
        .nav.navbar-nav {font-size:15px;}
        #searchForm {margin-right: 100px}
        @media (max-width: 1200px) {#searchForm {display:none}}
        li > a.active {color: #F97924}
        .nav-link {border-left:none!important;border-right:none!important;}
        /*.navbar-collapse{height:2.5rem!important}*/
        .nav-link:hover {background-image:none!important;}
        .doc-button-left{
            position:fixed!important;
            bottom:0;
            left:0;
            z-index:999;
        }
        .doc-button-right{
            position:fixed!important;
            bottom:0;
            right:0;
            z-index:999;
        }
        .navbar-custom-height {min-height:2.5rem;}
        .cdk-overlay-dark-backdrop {background:rgba(0,0,0,.90)!important;}
        .mat-bottom-sheet-container {background:none!important;box-shadow:0px 0px 0px transparent !important;}
        .mat-menu-panel {max-width:500px!important;}
    `],
    animations:[
        logoAnimation,
        ngIfAnimation
    ],
    encapsulation:ViewEncapsulation.None
})

export class NavBarComponent implements OnDestroy{

    @ViewChild('searchInput',{static:false}) searchInput: ElementRef
    @ViewChild('clickHoverMenuTrigger',{static:false}) clickHoverMenuTrigger: MatMenuTrigger;
    @ViewChild('2fa',{static:false}) twoFa

    state: string = 'startLogo'
    searchTerm:string = ""
    badgeCounter:number
    hideMatBadge:boolean
    interval
    messageNum
    messagesArr
    messArr
    userToken
    tokenDetails
    lengthArr:Array<any>
    searchResults:any
    is2Fa:Boolean = false
    turnOff2Fa:boolean = false
    searchloader:boolean = false
    private ngUnsubscribe = new Subject()
    private subject: Subject<string> = new Subject()

    authCodeOffGroup = new FormGroup({
        authOffCode: new FormControl('',Validators.required)
    })

    
    constructor(
        public auth:AuthService,
        public router:Router, 
        public api:RestApiService,
        private _bottomSheet: MatBottomSheet, 
        private tokenService:TokenService,
        private getMessages:NewsPanelService,
        private decodeToken:DecodeTokenService,
        private record:RecordService,
        private ns:NewsPanelService,
        private dialog:MatDialog,
        private account:AccountService,
        private snackBar: MatSnackBar,
        private communicationService:CommunicationService,
    ){
        this.badgeCounter = 0
        this.hideMatBadge = true

        this.communicationService.errorEvent.subscribe((result)=>{
            clearInterval(this.interval)
        })

        this.communicationService.openTalkTimePanel$.subscribe((data)=>{
            this.openTalkTimeEvidence(data)
        })

        router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this.ngUnsubscribe)  
          )
          
          .subscribe((event: NavigationEnd) => {
            let link = event.url
            if(link !== "/login" && link !== "/error"){
                if(link !== "/"){
                    this.userToken = this.tokenService.getSubjectToken()
                    this.tokenDetails = this.decodeToken.decodeToken(this.userToken) as tokenDetails
                    if(this.interval === undefined){
                        this.startMessageTimer()
                        this.firstRun()
                    } else {
                        clearInterval(this.interval)
                        this.startMessageTimer()
                        this.firstRun()
                    }
                    
                }
            }
          });

        this.getMessages.change
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(value => {
            if(value){
                value === 0 ? this.hideMatBadge = true : this.hideMatBadge = false
                this.badgeCounter = value
            }
        })
    }
    ngOnInit(){
        this.state = (this.state === 'startLogo' ? 'stopLogo' : 'startLogo')

        this.subject.pipe(
            //filter(Boolean),
            debounceTime(700),
            distinctUntilChanged()
        )
        .subscribe(searchTextValue => {
            this.searchRecords(searchTextValue)
          });

        this.ns.change.subscribe(data=>{
            this.badgeCounter = data
            this.badgeCounter > 0 ? this.hideMatBadge = false : this.hideMatBadge = true
        })
    }

    ngAfterViewInit(){

    }

    getMenuData(){
        this.account.twoFAStatus()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp =>{
            let respObj = <any> resp.body
            this.is2Fa = respObj.isSuccess
        })
    }

    clearMenu(){
        this.is2Fa = false
        this.turnOff2Fa = false
    }

    searchBar(event){
        this.subject.next(this.searchInput.nativeElement.value)
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    searchRecords(searchTerm){
        if(searchTerm.length > 1){
            this.searchloader = true
            this.record.getSearchRecord(searchTerm)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(resp=>{
                this.searchResults = <any>resp.body
                this.searchloader = false
                setTimeout(()=>{
                    this.clickHoverMenuTrigger.openMenu();
                    this.searchInput.nativeElement.focus();
                },0) 
            })
        } else if(searchTerm.length === 0){
            this.searchResults = [];
            this.clickHoverMenuTrigger.closeMenu();
        }
    }

    checkNavigationVisibility(page,linkName){
        if(page !== "/login" && page !== "/"){
             return this.decodeToken.accessGranted(linkName)
        } else {
            return false
        }
    }

    openBottomSheet():void{
        this._bottomSheet.open(BottomSheetDoc,{
            panelClass:'bottom-sheet-size'
        });
    }
    openBottomSheetNews():void{
        this._bottomSheet.open(BottomSheetNews,{
            data:this.messArr
        });
    }

    openSalaryEvidence(){
        this._bottomSheet.open(BottomSheetSalary,{
            panelClass:'bottom-sheet-size'
        })
    }

    openTalkTimeEvidence(data){
        this._bottomSheet.open(BottomSheetTalk,{
            panelClass:'bottom-sheet-size',
            data:data
        })
    }

    logoutUser(){
        clearInterval(this.interval)
        this.interval = undefined
        this.tokenService.logoutUser()
        this.router.navigate(['login'])
       
    }

    startMessageTimer(){
        this.interval = setInterval(()=>{
            this.getMessages.getAllValidMsgForUser()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(resp =>{
                this.messArr = []
                this.lengthArr = []
                this.messageNum = resp.body
                this.messArr = resp.body
                this.lengthArr = [resp.body]
                this.badgeCounter = this.lengthArr[0].filter(el => el.isReaded === false).length
                this.badgeCounter > 0 ? this.hideMatBadge = false : this.hideMatBadge = true
            })
        },60000)        
    }

    firstRun(){
        this.getMessages.getAllValidMsgForUser()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp =>{
            this.messArr = []
            this.lengthArr = []
            this.messageNum = resp.body
            this.messArr = resp.body
            this.lengthArr = [resp.body]
            this.badgeCounter = this.lengthArr[0].filter(el => el.isReaded === false).length
            this.badgeCounter > 0 ? this.hideMatBadge = false : this.hideMatBadge = true
        })        
    }

    openRecord(record,creditApplicationID){
        this.record.getRecordById(record,creditApplicationID)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            if(resp){
                let body = <any> resp.body
                body["creditApplicationId"] = creditApplicationID
                sessionStorage.setItem("manual",body.recordId)
                this.router.navigateByUrl('/search',{state:{body}})
            }
        })
    }

    twoFactorAuthChange(event){
        switch(event.checked){
            case true:{
                this.account.getQRCode()
                    .pipe(takeUntil(this.ngUnsubscribe))
                    .subscribe(resp=>{
                        let respData = <any> resp.body
                        this.openDialog(respData)
                    })
                break;
            }
            case false:{
                this.turnOff2Fa = true
                break;
            }
        }
    }

    disable2Fa(){
        let formValue = this.authCodeOffGroup.value
        this.account.twoFAOff(formValue.authOffCode)
        .pipe(
            catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 1000,
                    panelClass: "bg-danger"
                });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                let respObj = JSON.parse(resp.body)
                let message = respObj.message
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });

                if(respObj.isSuccess){
                    this.authCodeOffGroup.controls.authOffCode.reset()
                    this.turnOff2Fa = false
                }
        });
    }

    openDialog(respData) {
        const dialogRef = this.dialog.open(TwoFactorAuthDialog, {
            // maxWidth:'100vw',
            // width:'100vw',
            data: {
                //animal: 'panda'
                respData
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.twoFa.checked = result
        })
    }
}

@Component({
    selector:'two-factor-dialog',
    templateUrl: './two-factor-auth.html'
})

export class TwoFactorAuthDialog implements OnDestroy{
    qrCode:any
    manualCode:any
    respData:any
    dataON:any
    private ngUnsubscribe = new Subject()

    authCodeGroup = new FormGroup({
        authCode:new FormControl('',Validators.required)
    })

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public account:AccountService,
        public snackBar:MatSnackBar,
        public dialogRef: MatDialogRef<TwoFactorAuthDialog>
    ){}

    ngOnInit(){
        this.dataON = this.data
    }

    closeAuthDialog(flag){
        this.dialogRef.close(flag)
    }

    validate2Fa(){
        this.account.validateTwoFaCode(this.authCodeGroup.value.authCode)
        .pipe(
            catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 1000,
                    panelClass: "bg-danger"
                });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                let respObj = JSON.parse(resp.body)
                let message = respObj.message
                let respId = respObj.itemId
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });
                if(respObj.isSuccess){
                    this.closeAuthDialog(true)
                }
        });
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}

@Component({
    selector: 'bottom-sheet-doc',
    templateUrl: './bottom-sheet-doc.html',
  })
  export class BottomSheetDoc  {
    private ngUnsubscribe = new Subject()
    initialize:boolean = false
    constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetDoc>) {}

    ngOnInit(){
        //setTimeout(()=>{
            this.initialize = !this.initialize
        //},7000)
    }
  
    openLink(event: MouseEvent): void {
      this._bottomSheetRef.dismiss();
      event.preventDefault();
    }

    close(){
        this._bottomSheetRef.dismiss(true)
    }
  }

  @Component({
    selector: 'bottom-sheet-news',
    templateUrl: './bottom-sheet-news.html',
    styles:[`
    .header-news {
        left: -18px !important;
        position: absolute !important;
        transform: rotate(270deg) !important;
        top: 46px !important;
    }
    .mat-bottom-sheet-container{
        padding-left:53px!important;
        padding-top:0px!important;
    }
    .mat-list-base {
        padding-top:0px!important;
    }


    `],
    encapsulation:ViewEncapsulation.None
  })
  export class BottomSheetNews implements OnDestroy {
      boxMess:Array<any>
      userToken
      tokenDetails
      private ngUnsubscribe = new Subject()

    constructor(
        private _bottomSheetRef: MatBottomSheetRef<BottomSheetNews>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        private ns:NewsPanelService,
        private communicationService:CommunicationService,
        private snackBar:MatSnackBar,
        private tokenService:TokenService,
        private decodeToken:DecodeTokenService    
    ){
        this.boxMess = data.sort(function(a,b){return b.instantMessageId - a.instantMessageId})
    }
  
    ngOnInit(){
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
      }

    markAsReaded(val){
        this.userToken = this.tokenService.getSubjectToken()
        this.tokenDetails = this.decodeToken.decodeToken(this.userToken) as tokenDetails

        this.communicationService.emitChange()
        this.ns.markAsRead(parseInt(this.tokenDetails.UserId),parseInt(val))
            .pipe(
               catchError((err: any) => {
                    this.communicationService.emitChange();
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                      });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                this.communicationService.emitChange();
                this.boxMess.find(item => item.instantMessageId == val).isReaded = true
                this.ns.emitBadgeValue(this.boxMess.filter(item => item.isReaded == false).length)
            }); 
    }

    none(){
        
    }
    
  }

@Component({
    selector:'bottom-sheet-salary',
    templateUrl:'./bottom-sheet-salary.html',
    animations:[
        arrowButton,
        arrowicon
    ]
})

export class BottomSheetSalary implements OnDestroy {
    salaryList=[]
    buttonState:string = 'buttonStart'
    iconState:string ="buttonRotate0"
    showLabel:boolean = true
    private ngUnsubscribe = new Subject()

    data = [{
        id:0,
        month: "Marzec",
        delay: 346,
        advance: 2300,
        baseGross: 3000,
        commission: 500,
        total: 5800,
        settled: false
    },
    {
        id:1,
        month: "Kwiecien",
        delay: 0,
        advance: 0,
        baseGross: 3000,
        commission: 500,
        total: 3500,
        settled: true
    }
    ]    

    constructor(
        private _bottomSheetRef: MatBottomSheetRef<BottomSheetNews>,
        private salary:SalaryService
    ){}

    close(){
        this._bottomSheetRef.dismiss(true)
    }

    ngOnInit(){
        this.salary.getPayrollUser()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.salaryList = <any> resp.body
            if(this.salaryList.length > 0){
                this.salaryList.sort(function(a,b){
                    let ac = a.payrollId
                    let bc = b.payrollId
                    return bc - ac
                })
            }
        })
    }

    expandListPanel(){
        // setTimeout(()=>{
        // },500)        
    }

    closeListPanel(){
        this.iconState = "buttonRotate0"
    }

    openedPanel(){
        this.iconState = "buttonRotate180"
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
    
}

@Component({
    selector:'bottom-sheet-talk',
    templateUrl:'./bottom-sheet-talk.html',
    styles:[`
        .table-row{
            background-color:transparent;
        }
        .table-row:hover{
            background-color:#80808052;
        }
    `],
    animations:[
        arrowButton,
        arrowicon
    ]
})

export class BottomSheetTalk implements OnDestroy {
    private ngUnsubscribe = new Subject()
    mainArr = []

    constructor(
        private _bottomSheetRef: MatBottomSheetRef<BottomSheetNews>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ){}

    ngOnInit(){
        this.mainArr = this.data
    }

    refreshData(event){
        //console.log(event)
    }

    close(){
        this._bottomSheetRef.dismiss(true)
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
