import { Component, ViewChild, Inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '../../../../node_modules/@angular/material';
import { DialogData } from '../employeelist/employee-list.component';
import { HttpResponse, HttpEventType } from '../../../../node_modules/@angular/common/http';
import { FileService } from '../../shared/file.service';
import { catchError, takeUntil } from '../../../../node_modules/rxjs/operators';
import { empty, Subject } from '../../../../node_modules/rxjs';
import { CommunicationService } from '../../services/communication/communication.service';


@Component({
    selector:'talk-time',
    templateUrl: './talk-time.component.html'
})

export class TalkTimeComponent{
    @ViewChild('talkTimeListObj',{static:false}) talkTimeListObj

    dateEnd
    dateStartMax
    fileNameDesc
    fileName = []
    percentageDone = 0
    fileList: FileList
    filesSize:number = 0
    POSTStatus:any
    canSubmit:any
    dateEndMax
    private formData:FormData = new FormData();
    private ngUnsubscribe = new Subject()

    talkTimeList = new FormGroup({
        team: new FormControl('',Validators.required),
        periodFrom: new FormControl('',Validators.required),
        periodTo: new FormControl('',Validators.required)
    })

    constructor(
        public dialog: MatDialog,
        public file:FileService,
        public snackBar: MatSnackBar,
        public communicationService:CommunicationService
    ){}

    ngOnInit(){
    }

    getTalkTimeListList(){

        this.talkTimeListObj.resetForm()
    }

    handleFileInput(event){
        this.fileList= event.target.files;
        this.fileNameDesc = this.fileList[0].name
        let tempArr = new Set()
        if(this.fileList.length > 0) {
            var i
            for(i=0;i< this.fileList.length;i++){
                let file: File = this.fileList[i];
                tempArr.add(file.name)
                this.filesSize = this.filesSize + file.size
                if(i === 0){
                    //this.uploadFiles()
                }
            }
            this.fileName = Array.from(tempArr)
            
            //let file: File = fileList[0];
            //let 
            //this.fileName = this.fileName.push(file.name)
            //tempArr.add(file.name)
            // this.formData.append('uploadFile', file, file.name)
            // this.formData.append('campaignId',this.uploadBaseFirstStep.value.baseNameDDL)
            // this.formData.append('campaignName',this.uploadBaseFirstStep.value.baseNameInput)
            // this.formData.append('assignUser','0')
        }
    }

    uploadTalkTimeFiles(){
        var i
        for(i=0;i< this.fileList.length;i++){
            let file: File = this.fileList[i];
            this.formData = new FormData()
            this.formData.append('file',file,file.name)
            this.file.uploadTalkTime(this.formData)
            .pipe(
            catchError((err: any) => {
                    this.POSTStatus = {error:err.error}
                    console.log(err)
                    this.snackBar.open(err.error, "X", {
                        duration: 10000,
                        panelClass: "bg-danger"
                    });
                    return empty()
                }),
                takeUntil(this.ngUnsubscribe)
            )
            .subscribe(resp =>{
                if(resp.type === HttpEventType.UploadProgress){
                    this.percentageDone = Math.round(100 * resp.loaded / resp.total)
                } else if(resp instanceof HttpResponse){
                    let respObj = <any> resp.body
                    let message = respObj.message
                    let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                    let dur = respObj.isSuccess? 3000:10000
                    if(respObj.isSuccess){
                        setTimeout(()=>{
                            this.fileName = []
                            this.percentageDone = 0
                            this.formData = new FormData
                        },1000)
                    }
                    this.snackBar.open(message, "X", {
                        duration: dur,
                        panelClass: messageClass
                    });
                }
            });
        }
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    validateMinMaxDate(event){
        switch(event.targetElement.id){
            case "saleDate": {
                this.dateEnd = event.value
                break;
            }
            case "paymentDate": {
                this.dateStartMax = event.value               
                break;
            }
        }
    }

    openDialog() {
        // this.dialog.open(TalkTimeDialog, {
        //     maxWidth:'99vw',
        //     //width:'99vw',
        //     data: {
        //         //animal: 'panda'
        //     }
        // });
        let mainArr = [
            {
                header:'01-01-2021',
                users:[
                    {
                        userName:'Adam Mickiewicz',
                        time1:'00:01:00',
                        time2:'00:01:00',
                        time3:'00:01:00',
                        time4:'00:01:00',
                        time5:'00:01:00',
                        sum:'00:10:00'
                    },{
                        userName:'Juliusz Słowacki',
                        time1:'00:01:00',
                        time2:'00:02:00',
                        time3:'00:03:00',
                        time4:'00:04:00',
                        time5:'00:05:00',
                        sum:'00:10:00'
                    }
                ]
            },
            {
                header:'02-01-2021',
                users:[
                    {
                        userName:'Adam Mickiewicz',
                        time1:'00:01:00',
                        time2:'00:01:00',
                        time3:'00:01:00',
                        time4:'00:01:00',
                        time5:'00:01:00',
                        sum:'00:10:00'
                    }
                ]
            },
            {
                header:'03-01-2021',
                users:[
                    {
                        userName:'Adam Mickiewicz',
                        time1:'00:01:00',
                        time2:'00:01:00',
                        time3:'00:01:00',
                        time4:'00:01:00',
                        time5:'00:01:00',
                        sum:'00:10:00'
                    }
                ]
            },
            {
                header:'04-01-2021',
                users:[
                    {
                        userName:'Adam Mickiewicz',
                        time1:'00:01:00',
                        time2:'00:01:00',
                        time3:'00:01:00',
                        time4:'00:01:00',
                        time5:'00:01:00',
                        sum:'00:10:00'
                    }
                ]
            },
            {
                header:'05-01-2021',
                users:[
                    {
                        userName:'Adam Mickiewicz',
                        time1:'00:01:00',
                        time2:'00:01:00',
                        time3:'00:01:00',
                        time4:'00:01:00',
                        time5:'00:01:00',
                        sum:'00:10:00'
                    }
                ]
            },
            {
                header:'Podsumowanie',
                users:[
                    {
                        userName:'Adam Mickiewicz',
                        sum:'01:10:00'
                    },{
                        userName:'Juliusz Słowacki',
                        sum:'02:10:00'
                    },{
                        userName:'Mikołaj Kopernik',
                        sum:'03:10:00'
                    },{
                        userName:'Gandalf Biały',
                        sum:'04:10:00'
                    }
                ]
            }
        ]
        
        this.communicationService.emitTalkTimePanelOpen(mainArr)
      }


}

@Component({
    selector: 'talk-time-dialog',
    templateUrl: 'talk-time-dialog.component.html',
  })
  export class TalkTimeDialog {
    
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    mainArr = [
        {date:'01-01-2021'},
        {date:'01-01-2021'},
        {date:'01-01-2021'},
        {date:'01-01-2021'},
        {date:'01-01-2021'}
    ]






    tstArr = [
        {
            user:'Jane Doe',
            data:[
                {
                    date:'01-01-2020',
                    values:{
                        time1:'00:01',
                        time2:'00:02',
                        time3:'00:03',
                        time4:'00:04',
                        time5:'00:05'
                    }
                },
                {
                    date:'02-01-2020',
                    values:{
                        time1:'01:01',
                        time2:'01:02',
                        time3:'01:03',
                        time4:'01:04',
                        time5:'01:05'
                    }
                },
                {
                    date:'03-01-2020',
                    values:{
                        time1:'02:01',
                        time2:'02:02',
                        time3:'02:03',
                        time4:'02:04',
                        time5:'02:05'
                    }
                },
                {
                    date:'04-01-2020',
                    values:{
                        time1:'03:01',
                        time2:'03:02',
                        time3:'03:03',
                        time4:'03:04',
                        time5:'03:05'
                    }
                },
                {
                    date:'05-01-2020',
                    values:{
                        time1:'04:01',
                        time2:'04:02',
                        time3:'04:03',
                        time4:'04:04',
                        time5:'04:05'
                    }
                },
                {
                    date:'06-01-2020',
                    values:{
                        time1:'05:01',
                        time2:'05:02',
                        time3:'05:03',
                        time4:'05:04',
                        time5:'05:05'
                    }
                }

            ]
        },{
            user:'Jane Doe II',
            data:[
                {
                    date:'01-01-2020',
                    values:{
                        time1:'00:01',
                        time2:'00:02',
                        time3:'00:03',
                        time4:'00:04',
                        time5:'00:05'
                    }
                },
                {
                    date:'02-01-2020',
                    values:{
                        time1:'01:01',
                        time2:'01:02',
                        time3:'01:03',
                        time4:'01:04',
                        time5:'01:05'
                    }
                },
                {
                    date:'03-01-2020',
                    values:{
                        time1:'02:01',
                        time2:'02:02',
                        time3:'02:03',
                        time4:'02:04',
                        time5:'02:05'
                    }
                },
                {
                    date:'04-01-2020',
                    values:{
                        time1:'03:01',
                        time2:'03:02',
                        time3:'03:03',
                        time4:'03:04',
                        time5:'03:05'
                    }
                },
                {
                    date:'05-01-2020',
                    values:{
                        time1:'04:01',
                        time2:'04:02',
                        time3:'04:03',
                        time4:'04:04',
                        time5:'04:05'
                    }
                },
                {
                    date:'06-01-2020',
                    values:{
                        time1:'05:01',
                        time2:'05:02',
                        time3:'05:03',
                        time4:'05:04',
                        time5:'05:05'
                    }
                }
            ]
        },{
            user:'Jane Doe III',
            data:[
                {
                    date:'01-01-2020',
                    values:{
                        time1:'00:01',
                        time2:'00:02',
                        time3:'00:03',
                        time4:'00:04',
                        time5:'00:05'
                    }
                },
                {
                    date:'02-01-2020',
                    values:{
                        time1:'01:01',
                        time2:'01:02',
                        time3:'01:03',
                        time4:'01:04',
                        time5:'01:05'
                    }
                },
                {
                    date:'03-01-2020',
                    values:{
                        time1:'02:01',
                        time2:'02:02',
                        time3:'02:03',
                        time4:'02:04',
                        time5:'02:05'
                    }
                },
                {
                    date:'04-01-2020',
                    values:{
                        time1:'03:01',
                        time2:'03:02',
                        time3:'03:03',
                        time4:'03:04',
                        time5:'03:05'
                    }
                },
                {
                    date:'05-01-2020',
                    values:{
                        time1:'04:01',
                        time2:'04:02',
                        time3:'04:03',
                        time4:'04:04',
                        time5:'04:05'
                    }
                },
                {
                    date:'06-01-2020',
                    values:{
                        time1:'05:01',
                        time2:'05:02',
                        time3:'05:03',
                        time4:'05:04',
                        time5:'05:05'
                    }
                }

            ]
        },{
            user:'Jane Doe IV',
            data:[
                {
                    date:'01-01-2020',
                    values:{
                        time1:'00:01',
                        time2:'00:02',
                        time3:'00:03',
                        time4:'00:04',
                        time5:'00:05'
                    }
                },
                {
                    date:'02-01-2020',
                    values:{
                        time1:'01:01',
                        time2:'01:02',
                        time3:'01:03',
                        time4:'01:04',
                        time5:'01:05'
                    }
                },
                {
                    date:'03-01-2020',
                    values:{
                        time1:'02:01',
                        time2:'02:02',
                        time3:'02:03',
                        time4:'02:04',
                        time5:'02:05'
                    }
                },
                {
                    date:'04-01-2020',
                    values:{
                        time1:'03:01',
                        time2:'03:02',
                        time3:'03:03',
                        time4:'03:04',
                        time5:'03:05'
                    }
                },
                {
                    date:'05-01-2020',
                    values:{
                        time1:'04:01',
                        time2:'04:02',
                        time3:'04:03',
                        time4:'04:04',
                        time5:'04:05'
                    }
                },
                {
                    date:'06-01-2020',
                    values:{
                        time1:'05:01',
                        time2:'05:02',
                        time3:'05:03',
                        time4:'05:04',
                        time5:'05:05'
                    }
                }
            ]
        },{
            user:'Jane Doe V',
            data:[
                {
                    date:'01-01-2020',
                    values:{
                        time1:'00:01',
                        time2:'00:02',
                        time3:'00:03',
                        time4:'00:04',
                        time5:'00:05'
                    }
                },
                {
                    date:'02-01-2020',
                    values:{
                        time1:'01:01',
                        time2:'01:02',
                        time3:'01:03',
                        time4:'01:04',
                        time5:'01:05'
                    }
                },
                {
                    date:'03-01-2020',
                    values:{
                        time1:'02:01',
                        time2:'02:02',
                        time3:'02:03',
                        time4:'02:04',
                        time5:'02:05'
                    }
                },
                {
                    date:'04-01-2020',
                    values:{
                        time1:'03:01',
                        time2:'03:02',
                        time3:'03:03',
                        time4:'03:04',
                        time5:'03:05'
                    }
                },
                {
                    date:'05-01-2020',
                    values:{
                        time1:'04:01',
                        time2:'04:02',
                        time3:'04:03',
                        time4:'04:04',
                        time5:'04:05'
                    }
                },
                {
                    date:'06-01-2020',
                    values:{
                        time1:'05:01',
                        time2:'05:02',
                        time3:'05:03',
                        time4:'05:04',
                        time5:'05:05'
                    }
                }
            ]
        },{
            user:'Jane Doe VI',
            data:[
                {
                    date:'01-01-2020',
                    values:{
                        time1:'00:01',
                        time2:'00:02',
                        time3:'00:03',
                        time4:'00:04',
                        time5:'00:05'
                    }
                },
                {
                    date:'02-01-2020',
                    values:{
                        time1:'01:01',
                        time2:'01:02',
                        time3:'01:03',
                        time4:'01:04',
                        time5:'01:05'
                    }
                },
                {
                    date:'03-01-2020',
                    values:{
                        time1:'02:01',
                        time2:'02:02',
                        time3:'02:03',
                        time4:'02:04',
                        time5:'02:05'
                    }
                },
                {
                    date:'04-01-2020',
                    values:{
                        time1:'03:01',
                        time2:'03:02',
                        time3:'03:03',
                        time4:'03:04',
                        time5:'03:05'
                    }
                },
                {
                    date:'05-01-2020',
                    values:{
                        time1:'04:01',
                        time2:'04:02',
                        time3:'04:03',
                        time4:'04:04',
                        time5:'04:05'
                    }
                },
                {
                    date:'06-01-2020',
                    values:{
                        time1:'05:01',
                        time2:'05:02',
                        time3:'05:03',
                        time4:'05:04',
                        time5:'05:05'
                    }
                }
            ]
        }                                                           
    ]

    dateArr={
        0:'2020-01-01',
        1:'2020-01-02',
        2:'2020-01-03',
        3:'2020-01-04',
        4:'2020-01-05',
        5:'2020-01-06',
    }

  }