import {trigger,transition,style,query,animateChild,group,animate,state,keyframes, stagger} from '@angular/animations'


export const slideInAnimation =
  trigger('routeAnimations', [
    transition('* <=> AnimIn', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%'})
      ]),
      query(':leave', animateChild(),{optional: true}),
      group([
        query(':leave', [
          animate('300ms ease-out', style({ left: '100%'}))
        ],{optional: true}),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ]),
    transition('* <=> AnimOut', [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        })
      ]),
      query(':enter', [
        style({ left: '-100%'})
      ]),
      query(':leave', animateChild(),{optional: true}),
      group([
        query(':leave', [
          animate('200ms ease-out', style({ left: '100%'}))
        ],{optional: true}),
        query(':enter', [
          animate('300ms ease-out', style({ left: '0%'}))
        ])
      ]),
      query(':enter', animateChild()),
    ])
  ])

  export const ItemBox =         
    trigger('itemBox',[
        state('activeoff',style({
            transform: 'scale(1)',
            boxShadow: '0px 0px 0px transparent'
        })),
        state('activeon',style({
            transform: 'scale(1.02)',
            boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)'
        })),
        transition('* => activeon',[
            animate(200,keyframes([
                style({transform:'scale(1)', offset:0}),
                style({transform:'scale(0.99)', offset:0.4}),
                style({transform:'scale(1.02)',boxShadow: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)', offset:1})
            ]))
        ]),
        transition('* => activeoff',[
        animate(200,keyframes([
                style({transform:'scale(1.02)', offset:0}),
                style({transform:'scale(0.99)', offset:0.4}),
                style({transform:'scale(1)',boxShadow: '0px 0px 0px transparent', offset:1})
            ]))
        ])
    ])

export const IconBox =
    trigger('iconBox',[
        state('iconHide',style({
            transform: 'scale(0)'
        })),
        state('IconShow',style({
            transform: 'scale(1)'
        })),
        transition('* => iconShow',[
            animate(300,keyframes([
                style({transform:'scale(0)', offset:0}),
                style({transform:'scale(1)', offset:1})
            ]))
        ]),
        transition('* => iconHide',[
        animate(300,keyframes([
                style({transform:'scale(1)', offset:0}),
                style({transform:'scale(0)', offset:1})
            ]))
        ])
    ])

export const logoAnimation =
    trigger('logo',[
        state('startLogo',style({
            transform: 'scale(0)'
        })),
        state('stopLogo',style({
            transform: 'scale(1)'
        })),
        transition('void => stopLogo',[
            animate(1000,keyframes([
                style({transform:'scale(0)', offset:0}),
                style({transform:'scale(1.2)', offset:0.4}),
                style({transform:'scale(1)', offset:1})
            ]))
        ])
    ])

export const arrowButton =
    trigger('arrow-button',[
        state('buttonStart',style({
            top: '-32px'
        })),
        state('buttonEnd',style({
            top: '-28px'
        })),
        state('buttonRotate180',style({
            transition: 'rotate(180deg)'
        })),
        state('buttonRotate0',style({
            transition: 'rotate(0deg)'
        })),
        transition('buttonStart => buttonEnd',[
            animate(100,keyframes([
                style({top: '-28px'})
            ]))
        ]),
        transition('buttonEnd => buttonStart',[
            animate(100,keyframes([
                style({top: '-32px'})
            ]))
        ]),
        transition('void => buttonRotate180',[
            animate(100,keyframes([
                style({transform: 'rotate(180deg)'})
            ]))
        ]),
        transition('buttonRotate180 => buttonRotate0',[
            animate(100,keyframes([
                style({transform: 'rotate(0deg)'})
            ]))
        ])
    ])

    export const arrowicon =
    trigger('arrow-icon',[
        state('buttonRotate180',style({
            transform: 'rotate(180deg)'
        })),
        state('buttonRotate0',style({
            transform: 'rotate(0deg)'
        })),
        transition('buttonRotate0 => buttonRotate180',[
            animate(300,keyframes([
                style({transform: 'rotate(0deg)'}),
                // style({transform: 'rotate(90deg)'}),
                style({transform: 'rotate(180deg)'})
            ]))
        ]),
        transition('buttonRotate180 => buttonRotate0',[
            animate(300,keyframes([
                style({transform: 'rotate(180deg)'}),
                // style({transform: 'rotate(90deg)'}),
                style({transform: 'rotate(0deg)'}),

            ]))
        ])
    ])

    export const ngIfAnimation =
    trigger(
      'box', 
      [
        transition(
          ':enter', 
          [
            style({width:'0%',opacity: 0,fontSize:'0%' }),
            animate('0.3s ease-out', 
                    style({width:'100%',opacity: 1,fontSize:'100%'}))
          ]
        ),
        transition(
          ':leave', 
          [
            style({width:'100%',opacity: 1,fontSize:'100%'}),
            animate('0.1s ease-in', 
                    style({width:'0%',opacity: 0,fontSize:'0%' }))
          ]
        )
      ]
    )
    trigger(
        'box2', 
        [
          transition(
            ':enter', 
            [
              style({width:'0%',opacity: 0,fontSize:'0%' }),
              animate('0.3s ease-out', 
                      style({width:'100%',opacity: 1,fontSize:'100%'}))
            ]
          ),
          transition(
            ':leave', 
            [
              style({width:'0%',opacity: 0,fontSize:'0%'})
            ]
          )
        ]
      )
    
    export const ngIfAnimation3 = 
    trigger('box3', [
        transition('* => *', [
  
          query(':enter', style({ opacity: 0 }), {optional: true}),
  
          query(':enter', stagger('100ms', [
            animate('0.6s', keyframes([
              style({opacity: 0, height: '0px', transform:'translateY(-75%)'}),
              style({opacity: .5, transform:'translateY(35%)'}),
              style({opacity: 1, height:'48px', transform:'translateY(0%)'}),
            ]))]), {optional: true})
        ])
      ])

