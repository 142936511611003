import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()

export class SalaryService{
    baseUrl: string = environment.backend.baseURL

    constructor(private http:HttpClient){}

    addPayroll(form){
        return this.http.post(`${this.baseUrl}` + '/Payroll/addPayroll', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data
                })
            );
    }

    updatePayroll(form){
        return this.http.put(`${this.baseUrl}` + '/Payroll/updatePayroll', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data
                })
            );
    }
    
    getPayrollTeam(form){
        let employee = form.employee
        let dateFrom = form.dateFrom
        let dateTo = form.dateTo
        return this.http.get(`${this.baseUrl}` + '/Payroll/getPayrollTeam?dateFrom=' + dateFrom + '&dateTo=' + dateTo + '&teamId=' + employee,{observe:'response',withCredentials:true})
    }

    getPayrollUser(){
        return this.http.get(`${this.baseUrl}` + '/Payroll/getPayrollUser',{observe:'response',withCredentials:true})
    }

    deletePayroll(payrollId){
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(payrollId),
        };
        return this.http.delete(`${this.baseUrl}` + '/Payroll/deletePayroll',options)
            .pipe(
                map(data => {
                    return data;
                })
            );
    }
}