import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()

export class RecordService {
    baseUrl: string = environment.backend.baseURL
    constructor(private http:HttpClient){}

    getRecord(){
        // this.http.get(`${this.baseUrl}` + '/Record/getNewRecord?userID=' + userID ,{observe:'response',withCredentials:true})
        //         .pipe(map(resp=>resp.type)

        return this.http.get(`${this.baseUrl}` + '/Record/getNewRecord',{observe:'response',withCredentials:true})
                       
    }

    storeClientData(data){
        localStorage.setItem("body",data)
    }

    removeClientFlagFromStorage(){
        localStorage.removeItem("body")
        return true
    }

    getRecordHistory(userID){
        return this.http.get(`${this.baseUrl}` + '/Record/getRecordHistory?recordID=' + userID ,{observe:'response',withCredentials:true}); 
    }

    saveRecord(recordInfo){
        return this.http.post(`${this.baseUrl}` + '/Record/saveRecord', recordInfo, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text'})
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );
    }

    getRecordById(record,creditApplicationID){
        return this.http.get(`${this.baseUrl}` + '/Record/getRecordDetails?recordID=' + record + '&creditApplicationID=' + creditApplicationID,{observe:'response',withCredentials:true}); 
    }

    getStageRecords(id,name){
        return this.http.get(`${this.baseUrl}` + '/Record/getStageRecords?stageID='+ id + '&stageName=' + name,{observe:'response',withCredentials:true}); 
    }

    saveProduct(data){
        return this.http.post(`${this.baseUrl}` + '/Product/saveProduct', data, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text'})
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );
    }

    updateProduct(data){
        return this.http.put(`${this.baseUrl}` + '/Product/updateProduct', data, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text'})
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );        
    }

    getProductsList(val?){
        return this.http.get(`${this.baseUrl}` + '/Product/getProductsList?stageId=' + val,{observe:'response',withCredentials:true}); 
    }

    getProductDetails(creditApplicationId){
        return this.http.get(`${this.baseUrl}` + '/Product/getProductDetails?creditApplicationId=' + creditApplicationId,{observe:'response',withCredentials:true}); 
    }

    saveCreditAppRelease(data){
        return this.http.post(`${this.baseUrl}` + '/Product/saveCreditAppRelease', data, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text'})
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );
    }

    getSearchRecord(searchTerm){
        return this.http.get(`${this.baseUrl}` + '/Record/getSearchRecord?searchField=' + searchTerm,{observe:'response',withCredentials:true});
    }

    getRecordsToModify(form){
        return this.http.get(`${this.baseUrl}` + '/Record/GetRecordsToModify?campaignId=' + form.campaign +'&typeOfSearching=' + form.searchType + '&dataSearching=' + form.searchValues,{observe:'response',withCredentials:true});
    }

    updateRecords(form){
        return this.http.put(`${this.baseUrl}` + '/Record/UpdateRecords', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data;
                })
            );
    }


}