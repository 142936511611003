import { Component, ViewChild, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from '../../../../node_modules/@angular/forms';
import { ngIfAnimation } from '../../animations/animations';
import { MatTableDataSource, MatSnackBar } from '../../../../node_modules/@angular/material';
import { AllowedIPService } from '../../services/allowedip/allowed-ip.service';
import { takeUntil, catchError } from '../../../../node_modules/rxjs/operators';
import { Subject, empty } from '../../../../node_modules/rxjs';

export interface PeriodicElement {
    addDate: string
    addedByUserId: number
    description: string
    ipaddress: string
    ipwhiteListId: number
    name: string
    networkType: string
    userFirstName: string
    userLastName: string
}

@Component({
    selector:'allowed-ip',
    templateUrl: './allowed-ip.component.html',
    animations:[
        ngIfAnimation
    ]
})

export class AllowedIpComponent implements OnDestroy{
    @ViewChild('newIpFormObj',{static:false}) newIpFormObj
    ipListArr:any = []
    managerArr:any =[]
    dateArr:any =[]
    showIP:any
    showIPName:any
    canSubmit:any
    private ngUnsubscribe = new Subject()

    newipForm = new FormGroup({
        ip:new FormControl('',Validators.required),
        name: new FormControl ('',Validators.required),
        addressType: new FormControl ('',Validators.required),
        description: new FormControl ('',Validators.required)
    })

    listIp = new FormControl('')
    listIpName = new FormControl('')
    listIpType = new FormControl('')
    listIpUser = new FormControl('')
    listIpDate = new FormControl('')

    filterValues = {
        ipaddress:'',
        name:'',
        userLastName:'',
        addDate:'',
        networkType:''
      }

    displayedColumns: string[] = ['ipaddress', 'name', 'networkType', 'userLastName','addDate', 'action'];
    dataSource : MatTableDataSource<PeriodicElement>;

    constructor(
        private ip:AllowedIPService,
        private snackBar: MatSnackBar
    ){}

    ngOnInit(){
        this.getIpList()

        let tempArrUsers = new Set()
        let tempArrDate = new Set()
        let tempArrType = new Set()

        for(let itm of this.ipListArr){
            tempArrUsers.add(itm.userLastName)
            tempArrDate.add(itm.addDate)
            tempArrType.add(itm.networkType)
        }
        this.managerArr = Array.from(tempArrUsers)
        this.dateArr = Array.from(tempArrDate)

        this.listIp.valueChanges.subscribe(val =>{
           this.filterValues.ipaddress = val
           this.dataSource.filter = JSON.stringify(this.filterValues)
        })

        this.listIpName.valueChanges.subscribe(val =>{
            this.filterValues.name = val.toLowerCase();
            this.dataSource.filter = JSON.stringify(this.filterValues)
         })

         this.listIpType.valueChanges.subscribe(val =>{
            this.filterValues.networkType = val.toLowerCase();
            this.dataSource.filter = JSON.stringify(this.filterValues)
         })

         this.listIpUser.valueChanges.subscribe(val =>{
            this.filterValues.userLastName = val.toLowerCase();
            this.dataSource.filter = JSON.stringify(this.filterValues)
         })

         this.listIpDate.valueChanges.subscribe(val =>{
            this.filterValues.addDate = val.toLowerCase();
            this.dataSource.filter = JSON.stringify(this.filterValues)
         })
    }

    getIpList(){
        this.ip.getWhiteIpList()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            this.ipListArr = <any> resp.body
            if(this.ipListArr.length > 0){
                this.ipListArr.sort(function(a,b){
                    let ac = a.invoiceId
                    let bc = b.invoiceId
                    return bc - ac
                })
                this.dataSource = new MatTableDataSource(this.ipListArr);
                this.dataSource.filterPredicate = this.createFilter();
            }
        })
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    createFilter(): (data: any, filter: string) => boolean {
        let filterFunction = function(data, filter): boolean {
          let searchTerms = JSON.parse(filter);
          return data.ipaddress.toLowerCase().indexOf(searchTerms.ipaddress) !== -1
            && data.name.toLowerCase().indexOf(searchTerms.name) !== -1
            && data.networkType.toLowerCase().indexOf(searchTerms.networkType) !== -1
            && data.userLastName.toLowerCase().indexOf(searchTerms.userLastName) !== -1
            && data.addDate.toLowerCase().indexOf(searchTerms.addDate) !== -1
        }
        return filterFunction;
      }

    addNewIp(){
        let formValues = {
            "Name": this.newipForm.value.name,
            "Ipaddress": this.newipForm.value.ip,
            "Description": this.newipForm.value.description,
            "NetworkType": this.newipForm.value.addressType            
        }
        this.ip.addWhiteIp(formValues)
        .pipe(
            catchError((err: any) => {
                  console.log(err)
                  this.snackBar.open(err.error, "X", {
                      duration: 10000,
                      panelClass: "bg-danger"
                    });
                  return empty()
              }),
              takeUntil(this.ngUnsubscribe)
          )
          .subscribe(resp =>{
                let respObj = JSON.parse(resp.body)
                let message = respObj.message
                let messageClass = respObj.isSuccess? "bg-success":"bg-danger"
                if(respObj.isSuccess){
                    this.getIpList()
                    this.newIpFormObj.resetForm();
                }
                this.snackBar.open(message, "X", {
                    duration: 3000,
                    panelClass: messageClass
                });
          });
    }

    removeIpAddress(ipId){
        this.ip.deleteWhiteIp(ipId)
        .pipe(
           catchError((err: any) => {
                console.log(err)
                this.snackBar.open(err.error, "X", {
                    duration: 10000,
                    panelClass: "bg-danger"
                  });
                return empty()
            }),
            takeUntil(this.ngUnsubscribe)
        )
        .subscribe(resp =>{
            //this.ipListArr.splice(this.ipListArr.findIndex(itm=>itm.ipwhiteListId === ipId),1)
            this.getIpList()
            let mess = <any> resp
            this.snackBar.open(mess.message, "X", {
                duration: 10000,
                panelClass: mess.isSuccess? "bg-success" : "bg-danger"
              });
        }) 
    }

    markTouched(){
        this.newipForm.markAllAsTouched()
    }

    markUnTouched(){
        this.newipForm.markAsUntouched()
    }
}