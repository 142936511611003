import {Component, ViewEncapsulation} from '@angular/core'
import {RouterOutlet} from '@angular/router'
import { DecodeTokenService } from '../services/authservice/decodetoken.service';


@Component({
    selector: 'control-panel',
    templateUrl: './control-panel.component.html',
    styles:[`
        .item-link {cursor:pointer;}
        .mat-expansion-panel-header {background-color: #3a3f44!important}
        .mat-expansion-panel {background-color: #3a3f44!important}
        .mat-header-cell {background-color:#3a3f44!important}
        .mat-row {background-color:#3a3f44!important}
        .mat-paginator-container {background-color:#3a3f44!important}
        /*.mat-expansion-panel-header {background-color:#3a3f44!important;}
        .mat-expansion-panel-header-title {color:white!important}
        .mat-expansion-indicator::after {color:white!important}*/   
    `],
    encapsulation:ViewEncapsulation.None
})

export class ControlPanelComponent {
    constructor(
        private decodeToken:DecodeTokenService
    ){}

    disableAnimation = true;
    ngAfterViewInit(): void {
      setTimeout(() => this.disableAnimation = false);
    }

    checkCPItemVisibility(value){
        return this.decodeToken.accessGranted(value)
    }
    
}