import { Injectable } from "@angular/core";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from '../../../node_modules/rxjs';
import { environment } from '../../environments/environment';

@Injectable()

export class FileService {

    baseUrl: string = environment.backend.baseURL
    constructor(private http:HttpClient){}

    convertToBase(file){
        return new Promise(obj=>{
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                obj(reader.result)
            };
        })
    }

    uploadBaseFile(form){
        return this.http.post(`${this.baseUrl}` + '/record/uploadRecordsToDB',form,{reportProgress:true,observe:'events'})
        .pipe(
            map(data=>{
                return data;
            })
        )
    }

    uploadTalkTime(form){
        return this.http.post(`${this.baseUrl}` + '/Talktime/uploadTalkTime',form,{reportProgress:true,observe:'events'})
        .pipe(
            map(data=>{
                return data;
            })
        )
    }

    uploadFile(fileDetails){
        return this.http.post(`${this.baseUrl}` + '/File/uploadFile', fileDetails, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text'})
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );
    }

    getRecordDocuments(recordID,creditApplicationId){
        return this.http.get(`${this.baseUrl}` + '/File/getRecordDocuments?recordId=' + recordID + '&creditApplicationId=' + creditApplicationId ,{observe:'response',withCredentials:true});         
    }

    downloadFile(recordDocumentId){
        return this.http.get(`${this.baseUrl}` + '/File/downloadFile?recordDocumentId=' + recordDocumentId ,{observe:'response',withCredentials:true});
    }

    deleteFile(recordDocumentId){
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(recordDocumentId),
        };
        return this.http.delete(`${this.baseUrl}` + '/File/deleteFile',options)
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );
    }

    unpinDocument(recObj){
       //'/Product/unpinDocument'
        const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
            body: recObj,
        };
        return this.http.delete(`${this.baseUrl}` + '/Product/unpinDocument',options)
            .pipe(
                map(data => {
                    return {"status": 200,"statusText": "Success", "message": "Success", "value": data};
                })
            );

    }

    getPhisicalFile(data){
        const linkSource = `data:` + data.fileFormat + `;base64,${data.fileBase64}`;
        const downloadLink = document.createElement("a");
        const fileName = data.fileName;
    
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }
}