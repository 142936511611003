import { Injectable } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({providedIn:'root'})

export class TokenService{
    private subject = new BehaviorSubject<any>(0)
    private returnToken
    saveSubjectGlobally(token){
        // this.subject.subscribe(data =>{

        // })
        this.subject.next(token)
        localStorage.setItem("userToken",token)

    }

    getSubjectToken():Observable<any>{
        this.returnToken = !this.subject ? localStorage.getItem("userToken"):this.subject.value
        return this.returnToken
    }
    
    logoutUser(){

        this.subject.next(0)
        localStorage.removeItem("userToken")
        localStorage.removeItem("menuTabIndex")
    }
}