import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recursive'
})
export class RecursiveSearchPipe implements PipeTransform {
    private searchedItems: Array<any> = [];
    private key: string;
    private prop: string;
    private type:number
    private childrenPropName: string;
    private parent:any;
    parentTree:any
  
    transform(way:any,value: any, key?: any, prop?: any, childrenProp?: any,type?:number ): any {
      if(key != undefined) {
        this.searchedItems = [];
        this.key = key.toLowerCase();
        this.prop = prop;
        this.childrenPropName = childrenProp;
        let searchResult = way === 1 ? this.searchRecursive(value,type) : this.searchRecursiveParent(value,type)
        return searchResult;
      }
      return value;
    }
  
    searchRecursive(value,type) {
      for(var i = 0; i < value.length; i++) {
        let lowerCaseName = value[i][this.prop].toLowerCase();
        if(lowerCaseName === this.key) {
          type === 0 ? this.searchedItems.push(value[i]):value[i].level === "0" ? this.searchedItems.push():this.searchedItems.push(this.parent)
        } else if(value[i][this.childrenPropName]) {
          if(value[i][this.childrenPropName].length > 0) {
            this.searchRecursive(value[i][this.childrenPropName],type);
          } else {
            this.parent = value[i]
          }
        }
      }
  
      return this.searchedItems;
    }

    searchRecursiveParent(value,type){
      for(var i = 0; i < value.length; i++) {
        let lowerCaseName = value[i][this.prop].toLowerCase();
        if(lowerCaseName === this.key) {
          if(value[i].level === "0"){
            return this.searchedItems = []
          } else {
            this.searchedItems.push(this.parentTree)
            return
          }
        } else if(value[i][this.childrenPropName].length > 0){
          this.parentTree = value[i]
          this.searchRecursiveParent(value[i][this.childrenPropName],type);
        }
      }
      return this.searchedItems;
    }

  }