import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from '@angular/common/http';

import { Observable, EMPTY, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommunicationService } from '../communication/communication.service';
import { LoginComponent } from '../../user/login.component';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private router:Router,
        private communication: CommunicationService
    ){

    }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          //console.error('An error occurred:', error.error.message);
          //console.log("ERROR z pierwszej kategorii")
          console.log(error)
          //localStorage.removeItem('userToken')
          //localStorage.removeItem("menuTabIndex")
          //this.router.navigate(['login'])
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          //console.error(`Backend returned code ${error.status}, body was: ${error.error}`);
          console.log(error)
          if(error.status === 401){
            this.communication.logoutService(true)
            localStorage.removeItem('userToken')
            localStorage.removeItem("menuTabIndex")
            this.router.navigate(['login']) 
          }
          //this.router.navigate(['error'])
        }

        // If you want to return a new response:
        //return of(new HttpResponse({body: [{name: "Default value..."}]}));

        // If you want to return the error on the upper level:
        //return throwError(error);

        // or just return nothing:
        //return EMPTY;
        return throwError(error)
      })
    );
  }
}