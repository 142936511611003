import {Component, ViewEncapsulation, Input, OnDestroy} from '@angular/core'
import { Router } from '@angular/router'
import { RecordService } from '../../services/record/record.service';
import { TokenService } from '../../services/tokenservice/tokenservice.service';
import { DecodeTokenService } from '../../services/authservice/decodetoken.service';
import { MatSnackBar } from '../../../../node_modules/@angular/material';
import {takeUntil} from 'rxjs/operators'
import { Subject } from '../../../../node_modules/rxjs';
import { ngIfAnimation } from '../../animations/animations';

export interface tokenDetails {
    unique_name: string;
    BoxAccess: string;
    UserId: string;
    nbf: number;
    exp: number;
    iat:number;
  }

@Component({
    selector: 'get-new-record',
    templateUrl: './get-new-record.component.html',
    styleUrls:['./get-new-record.component.css'],
    encapsulation: ViewEncapsulation.None,
})

export class GetNewRecordComponent implements OnDestroy {
    centered: any
    userToken:any
    tokenDetails:any
    private ngUnsubscribe = new Subject()

    @Input() callbackList:any
    @Input() groupedObj:any

    constructor(
        private router:Router,
        private record:RecordService,
        private tokenService:TokenService,
        private decodeToken:DecodeTokenService,
        private snackBar:MatSnackBar
    ){

    }

    ngOnInit(){

    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
      }

    getNewRecord(){
        this.record.getRecord()
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp => {
            if(typeof resp.body !== "string"){
                let body = <any> resp.body
                this.router.navigateByUrl('/record',{state:{body}})
            } else {
                let message = <string> resp.body
                this.snackBar.open(message, "X", {
                    duration: 10000,
                    panelClass: "custom-snack-info"
                });                
            }
        })
    }


}