import {Component} from '@angular/core'



@Component({
    selector:'add-client',
    templateUrl: './add-client.component.html',
    styles:[`
        em {float:right;color:#E05C65;padding-left:10px;}
    `]
})



export class AddClientComponent {
    newClientGroup:any



    

}