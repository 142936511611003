import { Component } from "@angular/core";


@Component({
    selector:'employee-split',
    templateUrl:'./employee-split.component.html'
})

export class EmployeeSplitComponent{
    firstTab:boolean = false
    secondTab:boolean = true

    employeeTabChange(event){
        switch(event.tab.textLabel){
            case "1":{
                this.secondTab = true
                break;
            }
            case "0":{
                this.firstTab = true
                break;
            }
        }
    }

    onAnimationDone(){

    }
}