import { Injectable } from "../../../../node_modules/@angular/core";
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';

@Injectable()

export class EmployeeService {
    baseUrl: string = environment.backend.baseURL

    constructor(private http:HttpClient){}
    
    getAllUsers(){
        return this.http.get(`${this.baseUrl}` + '/Users/getAllUsers',{observe:'response',withCredentials:true})
                            
    }

    getUserDetails(userId){
        return this.http.get(`${this.baseUrl}` + '/Users/getUser?userId=' + userId ,{observe:'response',withCredentials:true}); 
    }

    editUser(form){
        return this.http.put(`${this.baseUrl}` + '/users/editUser', form, { headers: { 'Content-Type': 'application/json-patch+json'}, responseType:'text',observe:'response'})
            .pipe(
                map(data => {
                    return data;
                })
            );

    }
}