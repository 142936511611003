import {Component,Input, ViewEncapsulation, OnDestroy, ViewChild, ElementRef, Renderer2, ViewChildren, QueryList, SimpleChanges} from '@angular/core'
import { Router } from '@angular/router'
import { RecordService } from '../../services/record/record.service';
import { MatTabChangeEvent, MatExpansionPanel, MatAccordion } from '../../../../node_modules/@angular/material';
import { takeUntil } from '../../../../node_modules/rxjs/operators';
import { Subject } from '../../../../node_modules/rxjs';
import { CommunicationService } from '../../services/communication/communication.service';
import { ngIfAnimation, ngIfAnimation3 } from '../../animations/animations';

@Component({
    selector: 'manual-records',
    templateUrl: './manual-records.component.html',
    styles:[`
        .custom-flex {flex-grow:0;}
        .main-box{
            height:67vh;
        }
    `],
    encapsulation:ViewEncapsulation.None,
    animations:[
        ngIfAnimation3
    ]
})

export class ManualRecordsComponent implements OnDestroy {
    @Input() clientList: any
    @Input() type: any
    @ViewChildren("grom") el:QueryList<any>
    private ngUnsubscribe = new Subject()
    resultsBoxes:any
    resultId:number
    OriginCallbackId:number
    recId:number
    clientm:any
    recordId:any
    panelClosed:boolean = false
    surnameFilter:any = ''
    surnameFilterClass:boolean = false
    surnameInputFilter:string
    peselFilter:any = ''
    peselFilterClass:boolean = false
    peselInputFilter:string
    bankFilter:any = ''
    bankFilterClass:boolean = false
    bankInputFilter:string = ''
    productFilter:any = ''
    productFilterClass:boolean = false
    productInputFilter:string =''
    creditApplicationFilter:any = ''
    creditApplicationFilterClass:boolean = false
    creditApplicationInputFilter:string
    nipFilter:any = ''
    nipFilterClass:boolean = false
    nipInputFilter:string
    resultFilter:any = ''
    resultFilterClass:boolean = false
    statusInputFilter:string = ''
    clientTypeFilter:any = ''
    clientTypeFilterClass:boolean = false
    clientTypeInputFilter:string = ''
    pcl:any
    showFilterHeder:boolean = false
    showCleanFiltersBtn:boolean = false
    productFilterArr:any
    bankFilterArr:any
    statusFilterArr:any
    filterVisible:boolean = false
    refreshFlag:boolean = false
    populateDetails:boolean = false

    constructor(
        private router:Router,
        private record:RecordService,
        private communicationService:CommunicationService,
        private rd:Renderer2
    ){}

    // getRecordDetails(itemType){
    //     this.router.navigateByUrl('record',{state:{id:'222',type:itemType}})
    // }

    ngOnInit(){
        // this.communicationService.changeEmittedPanel$.subscribe(data=>{
        //         this.el.forEach(data=>{
        //             this.refreshFlag = true
        //             data.close()
        //         })   
        // })
    }

    getRecordRecordDetails(sentRecordId){
        //this.recId = sentRecordId
            this.record.getRecordById(sentRecordId,0)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(resp=>{
                this.clientm = <any> resp.body
                this.resultsBoxes = JSON.parse(this.clientm.contacResultJsonSring).childItems
                this.recordId = this.clientm.recordId
                this.OriginCallbackId = this.clientm.callbackId 
                this.populateDetails = true                  
            })
    }

    ngOnChanges(change:SimpleChanges){
        this.filterVisible = (change.clientList.currentValue !== null) ? true:false

    }

    clearData(){
        if(this.refreshFlag === true){
            setTimeout(()=>{
                this.communicationService.emitManualRefresh(this.type)
            },1000)
            this.refreshFlag = false
        }
        this.populateDetails = false         
    }

    ngOnDestroy(){
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    applyDynamicFilter($event,val){
        const filterValue = typeof $event === "string" ? $event :(event.target as HTMLInputElement).value;
        this.pcl ? null:this.pcl=this.clientList

        switch(val){
            case "surname":{
                this.surnameFilter = filterValue
                this.surnameFilter !== ''? this.surnameFilterClass=true:this.surnameFilterClass=false;
                (this.surnameFilter === '' && this.surnameInputFilter !== '')?this.surnameInputFilter = '':null
                break;
            }
            case "pesel":{
                this.peselFilter = filterValue
                this.peselFilter !== ''? this.peselFilterClass=true:this.peselFilterClass=false;
                (this.peselFilter === '' && this.peselInputFilter !== '')?this.peselInputFilter = '':null
                break;
            }
            case "nip":{
                this.nipFilter = filterValue
                this.nipFilter !== ''? this.nipFilterClass=true:this.nipFilterClass=false;
                (this.nipFilter === '' && this.nipInputFilter !== '')?this.nipInputFilter = '':null
                break;
            }
            case "clientType":{
                this.clientTypeFilter = filterValue
                this.clientTypeFilter !== ''? this.clientTypeFilterClass=true:this.clientTypeFilterClass=false;
                (this.clientTypeFilter === '' && this.clientTypeInputFilter !== '')?this.clientTypeInputFilter = '':null
                break;
            }
            case "product":{
                this.productFilter = filterValue
                this.productFilter !== ''? this.productFilterClass=true:this.productFilterClass=false;
                (this.productFilter === '' && this.productInputFilter !== '')?this.productInputFilter = '':null
                break;
            }
            case "bank":{
                this.bankFilter = filterValue
                this.bankFilter !== ''? this.bankFilterClass=true:this.bankFilterClass=false;
                (this.bankFilter === '' && this.bankInputFilter !== '')?this.bankInputFilter = '':null
                break;
            }
            case "status":{
                this.resultFilter = filterValue
                this.resultFilter !== ''? this.resultFilterClass=true:this.resultFilterClass=false;
                (this.resultFilter === '' && this.statusInputFilter !== '')?this.statusInputFilter = '':null
                break;
            }
            case "creditApplication":{
                this.creditApplicationFilter = filterValue
                this.creditApplicationFilter !== ''? this.creditApplicationFilterClass=true:this.creditApplicationFilterClass=false;
                (this.creditApplicationFilter === '' && this.creditApplicationInputFilter !== '')?this.creditApplicationInputFilter = '':null
                break;
            }
        }

        this.showCleanFiltersBtn = (
            this.surnameFilter !== '' ||
            this.peselFilter !== '' ||
            this.nipFilter !== '' ||
            this.clientTypeFilter !== '' ||
            this.productFilter !== '' ||
            this.bankFilter !== '' ||
            this.resultFilter !== '' ||
            this.creditApplicationFilter !== ''
        )

        this.clientList = this.pcl.filter(el=>
            // el.bankName.toLowerCase().includes(this.bankFilter.toLowerCase()) && 
            // el.productName.toLowerCase().includes(this.productFilter.toLowerCase()) &&
            el.lastName.toLowerCase().includes(this.surnameFilter.toLowerCase()) &&
            // el.creditApplicationNr.toLowerCase().includes(this.creditApplicationFilter.toLowerCase()) &&
            el.pesel.toLowerCase().includes(this.peselFilter.toLowerCase()) &&
            el.nip.toLowerCase().includes(this.nipFilter.toLowerCase()) &&
            el.clientType.toLowerCase().includes(this.clientTypeFilter.toLowerCase()) //&&
            // el.productName.toLowerCase().includes(this.productFilter.toLowerCase()) &&
            // el.result.toLowerCase().includes(this.resultFilter.toLowerCase())
        )

        this.clientList.length === 0 ? this.showFilterHeder=true:null
    }

    clearSingleFilter(eve,val){
        this.applyDynamicFilter(eve,val)
    }

    clearFilters(){
        this.clientList = this.pcl
        this.surnameFilterClass = false
        this.peselFilterClass = false
        this.bankFilterClass = false
        this.productFilterClass = false
        this.creditApplicationFilterClass = false
        this.nipFilterClass = false
        this.resultFilterClass = false
        this.clientTypeFilterClass = false
        this.showCleanFiltersBtn = false
        this.surnameInputFilter = ''
        this.peselInputFilter = ''
        this.bankInputFilter = ''
        this.productInputFilter = ''
        this.creditApplicationInputFilter = ''
        this.nipInputFilter = ''
        this.statusInputFilter = ''
        this.clientTypeInputFilter = ''        
    }

    // applyFilter(event:Event){
    //     const filterValue = (event.target as HTMLInputElement).value;
    //     this.dataSource.filter = filterValue.trim().toLowerCase();
    // }
    openRecord(recordId){
        this.record.getRecordById(recordId,0)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(resp=>{
            if(resp){
                let body = <any> resp.body
                sessionStorage.setItem("manual",body.recordId)
                this.router.navigateByUrl('/record',{state:{body}})  
            }
        })
    }

}